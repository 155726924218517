import { Typography } from "@mui/material"
import { ShipInformation } from "adoms-common-lib"
import React from "react"
import { Popup } from 'react-map-gl';

type AisPopupProps = {
    aisInfo: ShipInformation
    setPortPopupInfo: React.Dispatch<React.SetStateAction<ShipInformation | undefined>>
}

/**
 * ポート情報を表示するポップアップ
 * @param props 
 * @returns 
 */
export const AisPopup: React.FC<AisPopupProps> = (props) => {
    return (
        <Popup
            longitude={props.aisInfo.longitude}
            latitude={props.aisInfo.latitude}
            anchor="bottom"
            onClose={() => props.setPortPopupInfo(undefined)}>

            <Typography variant='subtitle2'>
                {"船舶ID: " + props.aisInfo.userId}
            </Typography>
            {props.aisInfo.statusText ?
                <Typography variant='subtitle2'>
                    {"船舶状況: " + props.aisInfo.statusText}
                </Typography> : null}
            {props.aisInfo.speedOverGround !== 102.3 ?
                <Typography variant='subtitle2'>
                    {"対地速度: " + props.aisInfo.speedOverGround + "kt"}
                </Typography> :
                <Typography variant='subtitle2'>
                    {"対地速度が利用不可能です"}
                </Typography>
            }
            {props.aisInfo.courseOverGround !== 360 ?
                <Typography variant='subtitle2'>
                    {"方角: " + props.aisInfo.courseOverGround}
                </Typography> :
                <Typography variant='subtitle2'>
                    {"方角が利用不可能です"}
                </Typography>}
            <Typography variant='subtitle2'>
                {"緯度: " + props.aisInfo.latitude}
            </Typography>
            <Typography variant='subtitle2'>
                {"経度: " + props.aisInfo.longitude}
            </Typography>
            <Typography variant='subtitle2'>
                {"取得時間: " + props.aisInfo.timeStamp}
            </Typography>
        </Popup>
    );
};